import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion'
import {StaticImage} from "gatsby-plugin-image"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import Hero from "../components/hero"
import TestimonialSection from "../components/testimonials-section"
import ArrowRight from "../components/svg/arrowRight"
import Cta from "../components/cta"
import Seo from "../components/seo"
import ChevronDown from "../components/svg/chevronDown"

const WebDesignPage = () => (
    <Layout>
        <Seo title="Web design agency Sydney and Canberra | Sydney web designer" 
            description="Trusted Sydney web design agency. Launch Lab offer web design, product design and web development services to startups and companies throughout Australia."
            pathname="/web-design/"
        />

        <Hero
            h1="Web design agency Sydney and Canberra"  
            h2="We're local web designers and web developers in Australia"
        />

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">100+</span>
                            <p>We've worked on more than 100 websites, UIs and startup products.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">360</span>
                            <p>We offer a 360 degree service from UI design to web and application development.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">SYD / CAN</span>
                            <p>Our web design and development work is done onshore in Sydney and Canberra.</p>
                        </div>
                    </div>
                    <div className="work-jumbo-wrapper mt-4 with-radius">
                        <motion.div 
                            className="work-jumbo-wrapper with-radius"
                            initial="hidden"
                            animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [120, 0], scale: [0.9, 1] }} 
                            transition={{ ease: "easeOut", delay: 0.5, duration: 1 }}
                        >
                            <StaticImage 
                                src="../images/launch-lab-work-2024.png"
                                alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                                className="with-radius"
                            />
                        </motion.div>
                    </div>
                    <div className="flex flex-jc">
                        <Link to="/contact/" className="btn btn-lg btn-pink">
                        <span className="flex flex-ac">
                                Start your project
                            <ArrowRight />
                        </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Web design with results</h3>
                        </div>

                        <div className="col-67 long-text">
                            <p>
                                We focus on minimal clean web design, great user experience and delivering on business objectives.
                            </p>
                            <p>
                                When developing websites our preference is to use <Link to="/gatsby-developer/">Gatsby.js</Link> and Contentful as a <Link to="/headless-cms/">content management system</Link>. If you've never heard of those products it's ok. Just know that we optimise websites for page load speed and a secure powerful CMS.
                            </p>  
                            <p>We've also recently started using <Link to="/webflow-developer/">Webflow</Link>, to reduce web development costs, which could be a great fit depending on the requirements of your project.</p>                 
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Start your project
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">   
                    <h4 className="text-center">Recent website projects</h4>
                    <motion.div 
                        className="porti-row mt-3"
                        initial={{ opacity: 0, y: 120 }}
                        whileInView={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: 0, scale: [0.9, 1] }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", delay: "0.25", duration: 0.5 }}
                    >
                        <Link to="/wordpress-to-webflow-case-study/" className="porti-item">
                        <div className="porti-item-img bg-noise apos">
                            <StaticImage 
                                src="../images/apositive.png"
                                alt="A collage of the APositive website screens"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>APositive</h3>
                            <p>We designed and developed a new website on Webflow for this Australian Fintech company.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Webflow</div>
                                <div className="work-pill cms">CMS</div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/game-studio-website/" className="porti-item">
                            <div className="porti-item-img bg-noise apos">
                                <StaticImage 
                                    src="../images/bzt.jpg"
                                    alt="Bazooka Tango homepage screen grab"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Bazooka Tango</h3>
                                <p>We designed and developed a new website for this US-based game studio.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                </div>
                            </div>
                        </Link>
                    </motion.div>
                </div>
                
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-purple">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                Web Design FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What is your web design style? <ChevronDown />
                                </>
                                }
                            >
                                <p>We design websites with a minimal feel, that load quickly, comply with best practice on-page SEO, work perfectly across devices and place the user first.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What web design software do you use? <ChevronDown />
                                </>
                                }
                            >
                                <p>Our preference is to use <a href="https://www.figma.com">Figma</a>. It has become industry standard with UI design professionals and is a powerful tool for designing websites and creating clickable prototypes.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you offer web development services? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. We offer a full stack service and can assist with wireframing, web design, startup design, product design, <Link to="/web-development/">web development</Link> and full application development.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What does a website cost? <ChevronDown />
                                </>
                                }
                            >
                                <p>That is a tough question to answer. It depends on your requirements and the size of the project. We are a senior experienced Australian web development team and will always recommend a solution that meets your needs and budget. Why not contact us and we'll discuss your requirements. If we can't assist based on budget we'll tell you straight away.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you offer web design services to startups? <ChevronDown />
                                </>
                                }
                            >
                                <p>We certainly do. We've worked with over 30 startups across fintech, healthtech, edtech and marketplaces helping them with designing their first website, creating landing pages, designing their products and developing the applications too.</p>
                                <p>You can see more on our <Link to="/startup-web-designers/">startup web design</Link> page.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you only offer web design in Sydney? <ChevronDown />
                                </>
                                }
                            >
                                <p>No. Our agency is based in Sydney and Canberra but we have designed and developed websites for companies and startups from around Australia.</p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="Get a web design quote or a product design quote from a local onshore Sydney web design & development team."
        />
    </Layout>
)

export default WebDesignPage