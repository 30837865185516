import React from "react"
import Testimonial from "./testimonial"
import { motion } from 'framer-motion'
import Quotes from "../images/quote-marks.svg"

const TestimonialSection = ({ quote, name, title }) => (
    
    <section className="bg-purple">
      <div className="container-lg testi-container">
      <div className="container">
          <div className="row">
            <div className="quotes-icon">
              <img src={Quotes} alt="quote marks icon for testimonials section" />
            </div>
            <motion.div className="testi-row"
              initial={{ opacity: 0, y: 80 }}
              whileInView={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: 0 }}
              viewport={{ once: true }}
              transition={{ ease: "easeInOut", duration: 0.5 }}
            >
              <Testimonial
                  quote="I had the pleasure of working with Shaun and the Launch Lab team for 2 years in my time as Head of Marketing at Immutable. I highly recommend Shaun and his team, I can attest to the fact that he will always have the client's best interests in mind and give his honest thoughts and ideas, even if it is at the expense of banking less hours on his end."
                  name="Alexandra Thompson"
                  title="Head of Marketing, Immutable"
              />
              <Testimonial
                  quote="Launch Lab were a pleasure to work with, extremely competent and have provided a great outcome for this project. Project delivery was professional, efficient and effective."
                  name="Manja Hausdorf-Adamson"
                  title="City of Sydney"
              />
              <Testimonial
                  quote="We considered several Sydney web development houses before settling on Launch Lab, and have been pleased every step of the way."
                  name="George Racz"
                  title="CEO, Halogen Health"
              />
              <Testimonial
                  quote="Launch Lab are extremely competent and professional and are also pleasure to work with. I highly recommend Launch Lab!"
                  name="Christopher Martell"
                  title="Director of Operations and Engineering, GSES"
              />
              <Testimonial
                  quote="Amazing team, really helped our business in the early stages. Shaun and David were great to work with. 10/10. Safe pair of hands and very cost effective."
                  name="Jonty Hirsowitz"
                  title="CEO, Deferit"
              />
              <Testimonial
                  quote="I worked with Launch Lab on the development of my company's website. The entire experience has been exceptional and I'd be really keen to work with them again."
                  name="Russ Watts"
                  title="Head of Marketing, Waddle (a Xero company)"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
)

export default TestimonialSection